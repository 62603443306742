import React from 'react'

//-> Css
import './OurServices.css'

//-> Bootstrap Components
import Col  from 'react-bootstrap/Col'
import Row  from 'react-bootstrap/Row'

//-> Components
import ServicesCard from '../../Components/ServicesCard/ServicesCard';




const OurServices = () => {

    const serviceCardData = [
        {   
            icon : "MdOutlineRealEstateAgent",
            subtitle : "Buying Home",
            text : "Some quick example text to build on the card title and make up the bulk of the card's content.",
        },
        {
            icon : "MdOutlineHouse",
            subtitle : "Renting Home",
            text : "Some quick example text to build on the card title and make up the bulk of the card's content.",
        },
        {
            icon : "MdOutlineAttachMoney",
            subtitle : "Selling Home",
            text : "Some quick example text to build on the card title and make up the bulk of the card's content.",
        },
    ]



    return (
        <div className='ourServices' id="ourservices">
            <h3 className='text-center m-5'> Nos services </h3>
            <div className='cardContainer'>
                <Row>
                    {
                        serviceCardData?.map(elem => {
                            return(
                                <Col className="col-12 col-md-6 col-lg-4">
                                    <ServicesCard icon={elem.icon} subtitle={elem.subtitle} text={elem.text}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    )
}

export default OurServices