import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'

//-> Css
import './ItemDetail.css'

//-> Bootstrap React
import Carousel from 'react-bootstrap/Carousel';
import Container  from 'react-bootstrap/Container';

//-> Icon
import { MdDone,MdClose } from "react-icons/md"

//-> Redux Function
import { getItem } from '../../Redux/Actions/Item';



const ItemDetail = () => {

    //> Get ID
    const { id } = useParams();

    //> Redux
    const dispatch = useDispatch();
    const hotelList = (useSelector(state => state.itemReducer.itemList))?.find(x => x.id == id)


    //> Use Effect
    useEffect(()=>{ dispatch(getItem()); },[])

    return (
        <div class="container">
            <div class="d-flex justify-content-center">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-5 col-md-5 col-sm-6">
                            <Container fluid border style={{'marginTop': "3rem"}}>
                                <Carousel border fade style={{'height':"100%",'width':"30rem"}}>
                                    {
                                        hotelList?.image?.map(elem => {
                                            return(
                                                <Carousel.Item style={{'height':"300px", 'width':'200px'}}>
                                                    <img  src={elem} alt="First slide" />
                                                </Carousel.Item>
                                            )
                                        })
                                    }
                                </Carousel>
                            </Container>
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-6">
                            <h4 class="box-title mt-5"> {hotelList?.homeType} <b> 5 Pieces {hotelList?.space} m2 </b> </h4>
                            <h6 class="card-subtitle"> {hotelList?.city } </h6>
                            <h2 class="mt-5"> € {hotelList?.price} </h2>
                                             
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h3 class="box-title mt-5">General Info</h3>
                            <div class="table-responsive">
                                <table class="table table-striped table-product">
                                    <tbody>
                                        <tr>
                                            <td width="390"> Home Type </td>
                                            <td>{hotelList?.homeType}</td>
                                        </tr>
                                        <tr>
                                            <td width="390"> Bedrooms </td>
                                            <td>{hotelList?.bedrooms}</td>
                                        </tr>
                                        <tr>
                                            <td width="390">Elevators </td>
                                            <td>{hotelList?.elevators ? <MdDone/> : <MdClose/>}</td>
                                        </tr>
                                        <tr>
                                            <td width="390">Balcony</td>
                                            <td>{hotelList?.balcony ? <MdDone/> : <MdClose/>}</td>
                                        </tr>
                                        <tr>
                                            <td width="390"> Pool </td>
                                            <td>{hotelList?.pool ? <MdDone/> : <MdClose/>}</td>
                                        </tr>
                                        <tr>
                                            <td width="390"> Gym </td>
                                            <td>{hotelList?.gym ? <MdDone/> : <MdClose/>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    )
}

export default ItemDetail

