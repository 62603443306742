import React from 'react'

//-> Css
import './ItemCard.css'

//-> Bootstrap Elements
import Button from 'react-bootstrap/Button';
import Card  from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

//-> Icon
import { MdFavorite,MdMessage,MdCameraAlt } from "react-icons/md";


const ItemCard = (props) => {

    const handleOpenCardDeatail = (e) => { window.open("/itemDetail/"+ e.currentTarget.id , "_blank") }

    return (
        <Card className="CardBorder" id={props.data.id} onClick={handleOpenCardDeatail}>
            <Card.Img variant="top" src={props.data.image} />
            <Card.ImgOverlay className="overlay">
                <span className="title-on-image">Exclusivité</span>
                
                <Badge pill bg="primary" className='camera'>
                    <MdCameraAlt size={13} /> &nbsp;&nbsp;&nbsp;
                    {props.data.image.length}
                </Badge>

            </Card.ImgOverlay>
            <Card.Body>
                <Card.Title>{props.data.price}€</Card.Title>
                <Card.Subtitle>{props.data.homeType} <b>5 Pieces {props.data.space} m2</b></Card.Subtitle>
            </Card.Body>
            <Card.Footer className="d-flex justify-between">
                <Button variant="btn btn-outline-danger"  className="m-1"><MdFavorite /> Favoris</Button>
                <Button variant="btn btn-outline-primary" className="m-1"><MdMessage />  Message</Button>
            </Card.Footer>
        </Card>
    )
}

export default ItemCard