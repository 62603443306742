import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//-> Css
import './Publisher.css'

//-> Boostrap Components
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

//-> Components
import { Button } from '../../Components/Button/Button';

//-> Icon
import { MdDelete } from "react-icons/md"

//-> Redux Function
import { getItem } from '../../Redux/Actions/Item';




const Publisher = () => {

    //> Redux
    const dispatch = useDispatch();
    const hotelList = useSelector(state => state.itemReducer.itemList);

    //> Use Effect
    useEffect(()=>{ dispatch(getItem()); },[])

    //> Use State
    const [imageList,setImageList] = useState([])


    //> Function
    const onSelectFile = (event) => {

        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);

        const imagesArray = selectedFilesArray.map((file) => {
            return URL.createObjectURL(file);
        });

        setImageList((previousImages) => previousImages.concat(imagesArray));

        // FOR BUG IN CHROME
        event.target.value = "";
    };

    function deleteHandler(image) {
        setImageList(imageList.filter((e) => e !== image));
        URL.revokeObjectURL(image);
    }

    return (
        <div className="Auth d-flex justify-content-center align-items-center bg-dark ">
            <div className='addForm bg-white'>
                <Form className="m-5">

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> City </Form.Label>
                        <Col sm="9">
                            <Form.Select >
                                {
                                    hotelList?.map(elem => {
                                        return(
                                            <option value={elem.city}> {elem.city} </option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Home Type </Form.Label>
                        <Col sm="9">
                            <Form.Select >
                                {
                                    hotelList?.map(elem => {
                                        return(
                                            <option value={elem.homeType}> {elem.homeType} </option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Bedrooms </Form.Label>
                        <Col sm="9">
                            <Form.Control type="number" placeholder='Total Bedroom' /> 
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Price </Form.Label>
                        <Col sm="9">
                            <Form.Control type="number" placeholder='Total Bedroom' /> 
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Space </Form.Label>
                        <Col sm="9">
                            <Form.Control type="number" placeholder='Space' /> 
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Description </Form.Label>
                        <Col sm="9">
                            <Form.Control as="textarea" rows={2} />
                        </Col>
                    </Form.Group>
                
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Images </Form.Label>
                        <Col sm="9">
                            <Form.Control type="file" name="images" size="md" multiple accept="image/png , image/jpeg, image/webp"
                                onChange={onSelectFile}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Elevators </Form.Label>
                        <Col sm="2">
                            <Form.Check className='mt-2' /> 
                        </Col>   
                        <Form.Label column sm="2"> Pool </Form.Label>
                        <Col sm="2">
                            <Form.Check className='mt-2' /> 
                        </Col>   
                        <Form.Label column sm="2"> Gym </Form.Label>
                        <Col sm="2">
                            <Form.Check className='mt-2' /> 
                        </Col>                  
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2"> Balcony </Form.Label>
                        <Col sm="9">
                            <Form.Check className='mt-2' /> 
                        </Col>
                    </Form.Group>

                    <Row className="mt-4">
                    <Col className='text-center'>
                        <Button> publier </Button>
                    </Col>
                    </Row>
                </Form>
            </div>

            {  
                <div className='imageList bg-white d-flex flex-column'>
                    {
                        imageList?.map(elem => {
                            return(
                                <>
                                    <img src={elem} height="200" alt="upload" className="m-2 rounded" />
                                    <Button className='w-10 mb-2 ms-5 me-5' onClick={() => deleteHandler(elem)} ><MdDelete /></Button>
                                    <hr/>
                                </>
                            )
                        })
                    }
                </div>             
            }
        </div>

             
       
    )
}

export default Publisher