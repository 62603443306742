import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

//-> Css
import './SearchResult.css'

//-> Bootstrap 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//-> Components
import ItemCard from '../../Components/ItemCard/ItemCard';
import Navigationbar from '../../Components/NavigationBar/NavigationBar';

//-> Redux Function
import { getItem } from '../../Redux/Actions/Item';

const SearchResult = () => {

  //> Get ID
  const search = useLocation().search;
  const budgetMax    = new URLSearchParams(search).get('BudgetMax');
  const surfaceMin   = new URLSearchParams(search).get('SurfaceMin');

  //> Redux
  const dispatch = useDispatch();
  const hotelList = (useSelector(state => state.itemReducer.itemList))

  //> Use Effect
  useEffect(()=>{ dispatch(getItem()); },[])

  let filter = hotelList?.filter(d => d.price <= budgetMax & d.space <= surfaceMin)
 
  return (
    <>
      <Navigationbar/>
      <div className='search'>
        <h3 className='text-center m-5'> Search Result </h3>
        <Container fluid>
            <Row>
                {
                  filter.map(elem => {
                    return(
                        <Col className='col-xs-1 col-md-4 col-lg-3'>
                            <ItemCard data={elem} />
                        </Col>
                    )
                  })
                }
            </Row>
        </Container>   
      </div> 
    </>
  )
}

export default SearchResult