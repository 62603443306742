import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//-> Css
import './NavigationBar.css'

//-> Bootstrap
import Container from 'react-bootstrap/Container';
import Nav    from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

//-> Components
import { Button } from "../Button/Button";

//-> Files
import { routeList } from "../../Routes/routes";


const Navigationbar = () => {

    //-> Navigate
    const navigate = useNavigate();

    //> Use State
    const [navTransparent, setNavTransparent] = useState(false)


    const changeBackgroundNavbar = (e) => {
        if(window.scrollY > 7){setNavTransparent(true)}
        else{setNavTransparent(false)}
    }

    window.addEventListener('scroll',  changeBackgroundNavbar)




    return (
        <Navbar collapseOnSelect fixed="top" expand="lg" bg={`${navTransparent ? 'white shadow-lg' : 'transparent'}`} variant="white" className="header">
            <Container>
                <Navbar.Brand href="#home">Coinzare</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    {
                        routeList?.map((elem,index) => {
                            return(<Nav.Link key={index+1} href={elem?.path}>{elem?.name}</Nav.Link>)
                        })
                    }
                </Nav>
                <Nav>
                    <Nav.Link>
                        <Button onClick={() => navigate("/login")}> Login </Button>
                    </Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigationbar

/*<Nav.Link href="#">
                        <MdPerson size={23}/>
                    </Nav.Link>*/