import {  Navigate, Outlet }     from "react-router-dom";

const checkAuth = () => {
    if(localStorage.getItem('isAuth')) 
        return true
    
    return true
}

const ProtectedRoutes = () => {
    return checkAuth() ? <Outlet /> : <Navigate to="/login"  replace />;
}

export default ProtectedRoutes;