import { combineReducers } from 'redux';

//> Reducer
import itemReducer from './item';
import communesReducer from './Communes';


const rootReducer = combineReducers({
    itemReducer,
    communesReducer
})

export default rootReducer
