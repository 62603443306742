import { ActionTypes } from "../ActionType";


//-> File Data
import communesData from "../../Json/communes.json";


//> Get Item List
export const getCommunes =  () => dispatch => {

    dispatch({ type: ActionTypes.Communes_ITEM, payload:communesData })
    
}