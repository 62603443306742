import React,{ useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//-> Css
import './ItemList.css'

//-> Bootstrap 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//-> Components
import ItemCard from '../../Components/ItemCard/ItemCard';

//-> Redux Function
import { getItem } from '../../Redux/Actions/Item';



const ItemList = () => {

    //> Redux 
    const dispatch = useDispatch();
    const hotelList = useSelector(state => state.itemReducer.itemList);

    //> Use Effect
    useEffect(()=>{ dispatch(getItem()); },[])

 

    return (
        <div className='itemList' id="itemList">
            <h3 className='text-center m-5'> Hôtels & Appartement </h3>
            <Container fluid className="mt-4">
                <Row>
                    {
                        hotelList?.map(elem => {
                            return(
                                <Col className='col-xs-1 col-md-5 col-lg-3'>
                                    <ItemCard data={elem} />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>    
        </div>    
    )
}

export default ItemList