import { ActionTypes } from "../ActionType"


const itemReducer = (state = '', action) => {
    switch (action.type) {
        
        case ActionTypes.GET_ITEM: 
            return { ...state,itemList:action.payload }

        default:
            return state
    }
}

export default itemReducer