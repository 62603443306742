import React from 'react'

//-> Css
import './ServiceCard.css'

//-> Bootstrap 
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


//-> Icon
import * as Icons from "react-icons/md";




const ServicesCard = (props) => {
 
    const IconComponent = (iconName) => {
        const Icon = Icons[iconName] 
        return <Icon size={70} color={"orange"} />
    }

    return (
        <Card className="serviceCard mt-4">
            <Card.Body style={{ textAlign: "center" }}>

                <Card.Title> {IconComponent(props?.icon)} </Card.Title>
                
                <Card.Subtitle className="mb-2 text-muted" style={{ fontSize: "2rem" }}> {props?.subtitle} </Card.Subtitle>
                
                <Card.Text> {props?.text} </Card.Text>
                
                <Button className="button-size" variant="light"> Learn more </Button>

            </Card.Body>
        </Card>        
    )
}

export default ServicesCard