import React from 'react'
import { Route, Routes } from 'react-router-dom';

//-> Pages
import Layout from './Layout/Layout';
import Auth from './Pages/Auth/Auth';
import Publisher from './Pages/Publisher/Publisher';
import SearchResult from './Pages/SearchResult/SearchResult';
import ItemDetail from './Pages/ItemDetail/ItemDetail';
import Error from './Pages/Error/Error';

//-> Protected Routes
import ProtectedRoutes from './Routes/ProtectedRoutes';



function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />} />
      <Route path='/login' element={<Auth />} />
      <Route path='/search' element={<SearchResult />} />
      <Route path='/itemDetail/:id' element={<ItemDetail />} />
      <Route element={<ProtectedRoutes />}>
        <Route path='/publisher' element={<Publisher />} />
      </Route>
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;


/*<Route path='/itemDetail/:id' element={<ItemDetail />} />*/