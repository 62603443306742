import { ActionTypes } from "../ActionType";


//-> File Data
import announcementsData from "../../Json/announcement.json";


//> Get Item List
export const getItem =  () => dispatch => {

    dispatch({ type: ActionTypes.GET_ITEM, payload:announcementsData })
    
}