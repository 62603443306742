import React from 'react'

//-> Pages
import Navigationbar from '../Components/NavigationBar/NavigationBar';
import Home          from '../Pages/Home/Home';
import ItemList      from '../Pages/ItemList/ItemList';
import OurServices   from '../Pages/OurServices/OurServices';
import ContactUs     from '../Pages/ContactUs/ContactUs';
import Footer        from '../Pages/Footer/Footer'

const Layout = () => {
    return (
        <>
            <Navigationbar />
            <Home />
            <ItemList />
            <OurServices />
            <ContactUs />
            <Footer />
        </>
    )
}

export default Layout