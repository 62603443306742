import React,{ useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//-> Css
import './Home.css'

//-> Bootstrap Components
import Card from 'react-bootstrap/Card'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Form from 'react-bootstrap/Form'
import Col  from 'react-bootstrap/Col'
import Row  from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup';


//-> Components
import { Button } from '../../Components/Button/Button'

//-> Icon
import { MdSearch,MdPlace,MdEuro } from "react-icons/md"

//-> Redux Function
import { getCommunes } from '../../Redux/Actions/Communes'




const Home = () => {

  //> Redux 
  const dispatch = useDispatch();
  const communesList = useSelector(state => state.communesReducer.itemList);

  //> Use Effect
  useEffect(()=>{ dispatch(getCommunes()); },[])

  //> Functions

  const handleSubmitAcheterForm = (e) => {
    e.preventDefault();

    var budgetMax = e.target.BudgetMax.value;
    var surfaceMin = e.target.SurfaceMin.value;

    //e.currentTarget.id

    window.open("/search?BudgetMax="+budgetMax+"&SurfaceMin="+surfaceMin , "_blank")
 
  }


  return (
    <div className="home" id="home">
      <div className='content'>
        {/* Heading */}
        <h1 className='text-dark text-center mt-2'>12 agents immobiliers partout, rien que pour vous !</h1>
        {/* Card */}
        <Card className="mt-4 tabsCard">
          <Card.Body>
            {/* Tab */}
            <Tabs fill defaultActiveKey="Acheter" id="fill-tab-example" className="mb-3" justify>
              {/* Tab:1 */}
              <Tab eventKey="Acheter" title="Acheter">
                
                <Form className='m-5' onSubmit={handleSubmitAcheterForm}>
                  <Row className="mb-3">

                    <Col className="col-12 col-md-4 col-lg-4">
                      <InputGroup className="mb-3">
                        <InputGroup.Text> < MdPlace/> </InputGroup.Text>
                        <Form.Select name="commune">
                          {
                            communesList?.map(elem => {
                              return(
                                <option value={elem.Nom_commune}> {elem.Nom_commune} </option>
                              )
                            })
                          }
                          
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    
                    <Col className="col-12 col-md-4 col-lg-4"> 
                      <InputGroup className='mb-3'>
                        <InputGroup.Text> <MdEuro /> </InputGroup.Text>
                        <Form.Control type="number" name="BudgetMax" placeholder='Budget Max' /> 
                      </InputGroup>    
                    </Col>

                    <Col className="col-12 col-md-4 col-lg-4"> 
                      <Form.Control type="number" name="SurfaceMin" placeholder='Surface Min' />
                    </Col>

                  </Row>

                  {/*<Row className="mt-4">
                    <Col className='text-center'>
                      <a variant="link" className='url btn btn-link' href='/search' target="_blank" > <MdSearch /> advanced search </a>
                    </Col>
                    </Row>*/}


                  <Row className="mt-4">
                    <Col className='text-center'>
                      <Button> <MdSearch /> Rechercher </Button>
                    </Col>
                  </Row>
                </Form> 
           
              </Tab>
              {/* Tab:2 */}
              <Tab eventKey="Louer" title="Louer">

                <Form className='m-5'>
                  <Row className="mb-3">

                    <Col className="col-12 col-md-4 col-lg-4">
                      <InputGroup className="mb-3">
                        <InputGroup.Text> < MdPlace/> </InputGroup.Text>
                        <Form.Select>
                          {
                            communesList?.map(elem => {
                              return(
                                <option value={elem.Nom_commune}> {elem.Nom_commune} </option>
                              )
                            })
                          }
                          
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    
                    <Col className="col-12 col-md-4 col-lg-4"> 
                      <InputGroup className='mb-3'>
                        <InputGroup.Text> <MdEuro /> </InputGroup.Text>
                        <Form.Control type="number" placeholder='Budget Max' /> 
                      </InputGroup>    
                    </Col>

                    <Col className="col-12 col-md-4 col-lg-4"> 
                      <Form.Control type="number" placeholder='Surface Min' />
                    </Col>

                  </Row>

                  <Row className="mt-4">
                    <Col className='text-center'>
                      <Button> <MdSearch /> Rechercher </Button>
                    </Col>
                  </Row>
                </Form>      

              </Tab>
            
            </Tabs>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

export default Home