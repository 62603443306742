import React from 'react'

//-> Css
import './ContactUs.css'

//-> Bootstrap 
import Form from 'react-bootstrap/Form';
import Col  from 'react-bootstrap/Col';
import Row  from 'react-bootstrap/Row';

//-> Components
import { Button } from '../../Components/Button/Button'

const ContactUs = () => {
    return (
        <div className='contactus' id="contactus" >
            <h3 className='text-center m-5'> Nous contacter </h3>
            <div className='ContactCard'>
                <Form className='m-5'>
                    <Row className="mb-3">
                        <Col> <Form.Label>Name</Form.Label> <Form.Control type="text" placeholder='Name' />   </Col>
                        <Col> <Form.Label>Email</Form.Label> <Form.Control type="email" placeholder='Email'/> </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col> <Form.Label>Subject</Form.Label> <Form.Control type="text" placeholder='Subject' /> </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col> <Form.Label>Message</Form.Label> <Form.Control as="textarea" rows={3} /> </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className='text-center'>
                            <Button  type="submit">  Submit  </Button> 
                        </Col>
                    </Row>
                </Form>
            </div>   
        </div>
    )
}

export default ContactUs