import React,{ useState } from 'react'

//-> Css
import './Auth.css'

//--> Components
import SignIn from './SignIn'
import SignUp from './SignUp'
import ResetPassword from './ResetPassword'



const Auth = () => {

    //> Use State
    const [formType, setFormType ] = useState(false)

    return (
        <div className="Auth d-flex justify-content-center align-items-center bg-dark ">
            <div className='login bg-white'>
                {
                    formType === false ?  <SignIn setFormType={setFormType}/>
                    :
                    formType === true ? <SignUp setFormType={setFormType} />
                    :
                    <ResetPassword setFormType={setFormType} />
                }
            </div>
        </div>
    )
}

export default Auth