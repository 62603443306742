import styled from "styled-components";

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background-color: rgb(220, 53, 69) ;
  color:white;

  font-size: 1em;
  padding: 0.25em 1em;
  border: 2px solid rgb(220, 53, 69);
  border-radius: 10px;
`;

export const LineButton =  styled.button`
  /* Adapt the colors based on primary prop */

  color:grey;

  font-size: 1em;
  padding: 0.25em 1em;
 
`;