import React from 'react'

//-> Css
import './Auth.css'

//-> Bootstrap Components
import Form from 'react-bootstrap/Form'
import Col  from 'react-bootstrap/Col'
import Row  from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup';

//-> Components
import { Button,LineButton } from '../../Components/Button/Button'

//-> Icon
import { MdEmail,MdArrowBack } from "react-icons/md"




const ResetPassword = (props) => {
    return (
        <React.Fragment>
            {/* Heading */}
            <h3 className='text-dark text-center pt-5'> Reset Password </h3>
                {/*Form */}
                <Form className="mt-5">
                    <Row className="m-3">
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text> <MdEmail /> </InputGroup.Text>
                                <Form.Control type="email" placeholder='Email' required />
                            </InputGroup>    
                        </Col>
                    </Row>

                    <Row className="mt-3 mb-4">
                        <Col className='text-center'>
                            <Button  type="submit" variant="success"> Send Email </Button>
                        </Col>
                    </Row>

                    <Row className="mt-3 mb-4">
                        <Col className='text-center'>
                            <LineButton variant="link" className='url btn btn-link' onClick={() => props.setFormType(false)}> 
                                <MdArrowBack/> 
                                Already account Login
                            </LineButton>
                        </Col>
                    </Row>

                </Form>
        </React.Fragment>
    )
}

export default ResetPassword