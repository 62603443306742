export const routeList = [
    {
        name:"Maison",
        path:"#home"
    },
    {
        name:"Hôtels & Appartement",
        path:"#itemList"
    },
    {
        name:"Nos services",
        path:"#ourservices"
    },
    {
        name:"Publier",
        path:"/publisher"
    },
    {
        name:"Nous contacter",
        path:"#contactus"
    }
    
]