import React from 'react'

//-> Css
import './Footer.css'

//-> Bootstrap 
import Container from 'react-bootstrap/Container';





const Footer = () => {
  return (
    <Container fluid className='footer bg-dark d-flex justify-content-center' >
      <p className='text-white mt-3'> © { new Date().getFullYear()} All Right Resereved </p>
    </Container>
  )
}

export default Footer