import { ActionTypes } from "../ActionType"


const communesReducer = (state = '', action) => {
    switch (action.type) {
        
        case ActionTypes.Communes_ITEM: 
            return { ...state,itemList:action.payload }

        default:
            return state
    }
}

export default communesReducer